import React from "react"
import style from "../css/header.module.scss"
import SvgOakay from "../images/svg_oakay"

const Header = () => (
  <header className={style.header}>
    <div className="container">
      <div className={style.logoWrapper}>
        <SvgOakay className={style.logo}/>
      </div>
    </div>
  </header>
)

export default Header
