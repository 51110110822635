import React from "react"

const SvgOakay = props => (
  <svg
    viewBox="0 0 1256 319"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={1.414}
    {...props}
  >
    <path fill="none" d="M0 0h1255.684v318.016H0z" />
    <g transform="matrix(4.16667 0 0 4.16666 -44.315 -65.317)">
      <path
        d="M98.688 63.915c8.617 0 11.49-4.896 11.064-16.279-2.553 1.596-7.235 1.914-11.17 2.978-4.043.959-7.449 2.662-7.449 7.021 0 4.469 3.513 6.28 7.555 6.28zm-23.406-28.62c.319-7.235 3.723-11.915 8.617-14.789 4.894-2.765 11.278-3.829 17.554-3.829 13.087 0 25.747 2.871 25.747 18.512v24.149c0 4.682 0 9.789 2.128 14.151h-18.194c-.637-1.703-.85-3.405-1.063-5.213-4.68 4.893-11.596 6.808-18.193 6.808-10.532 0-18.829-5.319-18.829-16.703 0-17.979 19.575-16.597 32.129-19.149 3.085-.639 4.787-1.702 4.787-5.107 0-4.148-5-5.746-8.724-5.746-5.002 0-8.193 2.235-9.043 6.916H75.282zM222.154 63.915c8.619 0 11.49-4.896 11.063-16.279-2.551 1.596-7.232 1.914-11.169 2.978-4.044.959-7.448 2.662-7.448 7.021 0 4.469 3.512 6.28 7.554 6.28zm-23.405-28.62c.319-7.235 3.722-11.915 8.617-14.789 4.893-2.765 11.276-3.829 17.553-3.829 13.086 0 25.747 2.871 25.747 18.512v24.149c0 4.682 0 9.789 2.128 14.151h-18.193c-.638-1.703-.851-3.405-1.064-5.213-4.681 4.893-11.596 6.808-18.193 6.808-10.531 0-18.83-5.319-18.83-16.703 0-17.979 19.575-16.597 32.129-19.149 3.087-.639 4.787-1.702 4.787-5.107 0-4.148-5-5.746-8.723-5.746-4.999 0-8.192 2.235-9.043 6.916h-16.915z"
        fillRule="nonzero"
      />
      <clipPath id="oakay_svg__a">
        <path d="M10.731 16.006h301.057V92H10.731z" />
      </clipPath>
      <g clipPath="url(#oakay_svg__a)">
        <path
          d="M289.977 78.382C285.616 90.511 279.125 92 266.892 92h-8.512V77.319h6.278c4.147 0 7.233-2.341 7.233-6.171 0-2.98-3.51-11.49-4.573-14.468l-14.256-38.407h19.149l10.426 35.96h.213l10.426-35.96h18.512l-21.811 60.109z"
          fillRule="nonzero"
        />
      </g>
      <path
        d="M192.617 18.273h-20.958l-16.49 18.405V18.273h-18.087v55.216h18.087v-17.66l4.68-4.576 13.512 22.236h21.81l-22.767-34.364 20.213-20.852zM40.217 61.138c-8.455 0-11.026-8.345-11.026-15.407 0-7.061 2.57-15.512 11.026-15.512 8.45 0 11.128 8.45 11.128 15.512 0 7.062-2.677 15.407-11.128 15.407m0-44.835c-17.23 0-29.211 12.739-29.211 29.428 0 16.694 11.982 29.322 29.211 29.322 17.225 0 29.317-12.628 29.317-29.322 0-16.689-12.092-29.428-29.317-29.428"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgOakay

